:root {
  --backgroundColor: #FFFFFF;
  --textColor: #000000;
  --linkColor: #000000;
  --linkHoverColor: #000000;
}
a.load {
  --linkColor: #000000;
  --linkHoverColor: #FFFFFF;
}
.section--moodvideo,
.section--black {
  --backgroundColor: #000000;
  --textColor: #FFFFFF;
  --linkColor: #FFFFFF;
  --linkHoverColor: #FFFFFF;
}
.section--moodvideo a.load,
.section--black a.load {
  --linkColor: #FFFFFF;
  --linkHoverColor: #000000;
}
.section--grey {
  --backgroundColor: #F0F0F0;
}
.unit--boxLinked {
  --linkColor: #000000;
  --linkHoverColor: #000000;
}
.unit--boxLinked.unit--hasBackground {
  --linkColor: #FFFFFF;
  --linkHoverColor: #FFFFFF;
}
.unit--unitGameDay {
  --backgroundColor: #000000;
  --textColor: #FFFFFF;
  --linkColor: #FFFFFF;
  --linkHoverColor: #FFFFFF;
}
.unit--unitGame {
  --backgroundColor: #F0F0F0;
}
:root {
  --spaceArea: calc(100px - var(--spacePart) - var(--spaceUnit));
}
@media (max-width: 1279px) {
  :root {
    --spaceArea: calc(100px - var(--spacePart) - var(--spaceUnit));
  }
}
@media (max-width: 767px) {
  :root {
    --spaceArea: calc(80px - var(--spacePart) - var(--spaceUnit));
  }
}
:root .section--three {
  --spaceArea: 0;
}
:root {
  --deskWidth: 920px;
  --deskSpace: 21px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
:root .section--one {
  --deskWidth: 732px;
}
:root .section--four {
  --deskWidth: 1108px;
}
@media (min-width: 768px) {
  :root {
    --deskSpace: 177px;
  }
  :root .navbar,
  :root .section--three,
  :root .section--four,
  :root .section--five,
  :root .section--footer {
    --deskSpace: 62px;
    --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
  }
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1279px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root .section--three {
  --spacePart: 0;
}
:root {
  --spaceUnit: 10px;
}
@media (max-width: 1279px) {
  :root {
    --spaceUnit: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 10px;
  }
}
:root .section--three {
  --spaceUnit: 0;
}
:root .unit--imageLeftRight {
  --spaceUnit: 30px;
}
:root {
  --spaceTotal: 20px;
}
@media (max-width: 1279px) {
  :root {
    --spaceTotal: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 20px;
  }
}
:root .section--four {
  --spaceTotal: 0;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #E92B22;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #dc1f16;
}
.button:active {
  background-color: #ad1811;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #E92B22;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 700;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 18px;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 1.33333333;
}
.unit caption {
  display: none;
}
.flag {
  background: #E92B22;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 4px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#expo:after {
  content: "";
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  width: 100%;
  height: 63%;
  background: url(/images/mood-thing.svg) no-repeat center center;
  background-size: 100% 100%;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 31.25%;
  position: relative;
}
@media (max-width: 1279px) {
  #slides {
    padding-bottom: 600px;
  }
}
@media (max-width: 767px) {
  #slides {
    padding-bottom: 355px;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #E92B22;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #E92B22;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 1.42857143;
  color: #000000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #E92B22;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #E92B22;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #E92B22;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #fff;
  background-color: #dc1f16;
}
#disp .foot input:active,
#disp .foot a:active {
  background-color: #ad1811;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1279px) {
  .cb-zoom-indicator {
    display: none;
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit--form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit--form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit--form div.ctrl {
  float: left;
  width: 100%;
}
.unit--form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit--form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit--form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit--form .tile .name {
    width: 30%;
    min-height: 60px;
    padding-top: 18px;
    padding-bottom: 18px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit--form input.text,
.unit--form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  border: 1px solid var(--textColor);
  min-height: 60px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit--form input.text.fail,
.unit--form textarea.fail {
  background-color: #e7bcbc;
}
.unit--form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit--form .tile input.text,
  .unit--form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit--form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit--form div.tick.tile div.ctrl > div {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
.unit--form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit--form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 24px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit--form div.tick.tile input {
    top: 18px;
  }
}
.unit--form select {
  float: left;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 18px;
  line-height: 1.33333333;
  background: transparent;
  height: 60px;
  border: 1px solid #000000;
  padding: 5px;
}
.unit--form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit--form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit--form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit--form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit--form .ship div.chop span {
  float: left;
}
.unit--form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit--form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit--form .unit__foot {
  position: relative;
  z-index: 1;
  display: inline-block;
  box-sizing: border-box;
  float: right;
  width: auto;
  margin-right: 10px;
  padding-left: 0;
  padding-right: 35px;
}
.unit--form .unit__foot:before {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  top: 50%;
  width: 15px;
  height: 100%;
  transform: translateY(-50%);
  background: url(/images/arrow-ticket.svg) no-repeat right center;
  background-size: 120px 15px;
  transition: all 0.3s;
}
.unit--form .unit__foot:hover,
.unit--form .unit__foot:focus {
  padding-left: 5px;
  font-weight: 700;
}
.unit--form .unit__foot:hover:before,
.unit--form .unit__foot:focus:before {
  width: 120px;
}
.unit--form input.submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  margin-right: -25px;
  padding-left: 0;
  padding-right: 25px;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
}
.unit--form input.submit:hover,
.unit--form input.submit:focus {
  padding-left: 5px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .unit--form .unit__foot {
    padding-right: 50px;
  }
  .unit--form .unit__foot:before {
    width: 30px;
    background-size: 240px 30px;
  }
  .unit--form .unit__foot:hover:before,
  .unit--form .unit__foot:focus:before {
    width: 190px;
  }
  .unit--form input.submit {
    font-size: 30px;
    line-height: 1.2;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000000;
  text-decoration: underline;
}
.unit--form .recaptcha-info {
  color: #aaa;
}
.unit--form .recaptcha-info a {
  color: #aaa;
}
.unit--form .recaptcha-info a:hover,
.unit--form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit--form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit--form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit--form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #E92B22;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #fff;
  background-color: #dc1f16;
}
.two-step-verification-container a:active {
  background-color: #ad1811;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 12px 12px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 700;
}
.table td {
  padding: 12px 12px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 700;
}
@media (max-width: 767px) {
  .part--table .table {
    display: block;
  }
  .part--table .table .table-head,
  .part--table .table .table-foot {
    display: none;
  }
  .part--table .table .table-body {
    display: block;
    width: 100%;
  }
  .part--table .table tr {
    float: left;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 5px 10px;
    border: 1px solid #ccc;
    margin-top: 9px;
  }
  .table-body .part--table .table tr:first-child {
    margin-top: 0;
  }
  .part--table .table .table-body tr:first-child {
    margin-top: 0;
  }
  .part--table .table td {
    float: left;
    display: block;
    width: 100%;
    padding: 5px 0;
  }
  .part--table .table.table--headline td {
    position: relative;
    box-sizing: border-box;
    padding-left: 52%;
  }
  .part--table .table.table--headline td:before {
    content: attr(data-title);
    position: absolute;
    left: 0;
    top: 0;
    width: 48%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    font-weight: 700;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 40px;
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 18px;
  line-height: 1.33333333;
  color: #000000;
  overflow: hidden;
  hyphens: none;
  padding-top: 60px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
  background-color: var(--backgroundColor);
  color: var(--textColor);
}
.navbar {
  float: left;
  width: 100%;
  position: fixed;
  z-index: 2001;
  left: 0;
  top: 0;
  height: 60px;
  background-color: #FFFFFF;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
}
.navigation {
  float: left;
  width: 100%;
}
.branding {
  position: absolute;
  z-index: 99;
  left: var(--deskSpace);
  top: 11px;
}
.branding .mainSponsor {
  float: left;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.branding .mainSponsor__title {
  display: block;
  font-size: 8px;
  line-height: 1.25;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.01em;
}
.branding .mainSponsor__image {
  display: block;
  margin-top: 2px;
  width: auto;
  height: 26px;
}
.mainNavi {
  float: left;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}
#naviServices {
  float: right;
  margin-right: 75px;
  display: flex;
  gap: 40px;
}
#naviServices .meta {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(/images/search.svg) no-repeat center center;
  background-size: contain;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#naviServices .meta.service_tickets {
  background-image: url(/images/ticket-icon.svg);
}
.container--moodvideo {
  height: calc(100vh - 60px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container--moodvideo .mood {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.container--moodvideo .mood__video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container--moodvideo .content {
  position: relative;
  z-index: 200;
  float: left;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  color: #FFFFFF;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.container--moodvideo .content .desk {
  z-index: 220;
}
.container--moodvideo .pageLogoContainer {
  text-align: center;
  height: auto;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.container--moodvideo .pageLogoContainer .pageLogo {
  float: unset;
  width: 290px;
}
.container--moodvideo .cbdModule--subTitle {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
#tickets {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 80px;
}
#tickets .meta {
  position: relative;
  z-index: 1;
  display: inline-block;
  box-sizing: border-box;
  width: 110px;
  padding-left: 0;
  padding-right: 25px;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.3s;
}
#tickets .meta:before {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  top: 50%;
  width: 15px;
  height: 100%;
  transform: translateY(-50%);
  background: url(/images/arrow-ticket.svg) no-repeat right center;
  background-size: 120px 15px;
  transition: all 0.3s;
}
#tickets .meta:hover,
#tickets .meta:focus {
  font-weight: 700;
}
#tickets .meta:hover:before,
#tickets .meta:focus:before {
  width: 120px;
}
.section--footer {
  font-size: 16px;
  line-height: 1.375;
}
.section--footer .logo {
  height: 40px;
}
.section--footer .footerArea {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: var(--spaceArea);
  margin-bottom: var(--spaceArea);
}
.section--footer .footerArea .footerUnit {
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
}
.section--footer .footerArea .footerUnit .footerPart {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.section--footer .footerArea .footerUnit .footerPart:first-child {
  margin-bottom: 40px;
}
#socialMedia {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
#socialMedia .meta {
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 50px;
  height: 50px;
  margin: 20px 13px;
  background: no-repeat center center;
  background-size: 100% 100%;
}
#socialMedia .meta:first-child {
  margin-left: 0;
}
#socialMedia .meta:last-child {
  margin-right: 0;
}
#socialMedia .meta.service_instagram {
  background-image: url(/images/instagram-icon.svg);
}
#socialMedia .meta.service_facebook {
  background-image: url(/images/facebook-icon.svg);
}
#socialMedia .meta.service_twitter {
  background-image: url(/images/twitter-icon.svg);
}
#disclaimer,
#poweredBy {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.footerPart .copy,
#poweredBy .copy {
  margin-top: 22px;
  margin-bottom: 22px;
}
.footerPart .meta.auth,
#poweredBy .meta.auth {
  margin-top: 22px;
}
a {
  color: var(--linkColor);
  text-decoration: none;
}
a:hover,
a:focus {
  color: var(--linkHoverColor);
}
.text a,
.section--footer a {
  text-decoration: underline;
  text-decoration-color: #E92B22;
}
.text-section--norm strong {
  font-weight: 500;
}
.layout1 h1 {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding-top: 0;
  font-size: 75px;
  line-height: 0.93333333;
  font-weight: 700;
  letter-spacing: 0em;
}
.layout1 h1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: 105%;
  height: 75px;
  background-color: #E92B22;
}
h1,
h2 {
  position: relative;
  padding-top: 33px;
  box-sizing: border-box;
  font-size: 24px;
  line-height: 1.16666667;
  letter-spacing: 0.02em;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
h1:before,
h2:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  height: 3px;
  width: 168px;
  background-color: #E92B22;
}
.layout2 .seam h2 {
  font-size: 30px;
  line-height: 0.83333333;
}
h3 {
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  font-weight: 700;
  text-transform: uppercase;
}
h4 {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-size: 55px;
  line-height: 0.90909091;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 767px) {
  h4 {
    font-size: 40px;
    line-height: 0.875;
  }
}
h4:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 105%;
  height: 35px;
  background-color: #E92B22;
}
.layout3 h4 {
  font-size: 30px;
  line-height: 0.83333333;
}
.layout3 h4:before {
  top: 12px;
  height: max(50%, 26px);
}
.loud {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 400;
  text-align: center;
  font-style: italic;
}
.section--one .loud {
  text-align: left;
}
.pale {
  font-size: 12px;
  line-height: 1.33333333;
}
.area {
  margin-top: var(--spaceArea);
  margin-bottom: var(--spaceArea);
}
.area.area--six {
  margin-bottom: 0;
}
.layout2 .area.area--one,
.layout3 .area.area--one,
.layout2 .area.area--nine {
  margin-top: 0;
}
.unit {
  box-sizing: border-box;
}
.unit.unit--boxLinked {
  aspect-ratio: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 30px;
  text-align: center;
}
.unit.unit--boxLinked .unit__background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.unit.unit--boxLinked .unit__background:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}
.unit.unit--boxLinked:not(.unit--hasBackground):before,
.unit.unit--boxLinked:not(.unit--hasBackground):after {
  content: "";
  position: absolute;
  left: 20px;
  top: 20px;
  width: 50px;
  height: 50px;
  background: url(/images/unitBoxCornerTopLeft.svg) no-repeat center center;
  background-size: 100% 100%;
  transform: scale(1);
  transform-origin: top left;
  transition: all 0.3s;
}
.unit.unit--boxLinked:not(.unit--hasBackground):after {
  top: unset;
  left: unset;
  right: 20px;
  bottom: 20px;
  background-image: url(/images/unitBoxCornerBottomRight.svg);
  transform-origin: bottom right;
}
.unit.unit--boxLinked:hover .unit__background:after,
.unit.unit--boxLinked:focus .unit__background:after {
  background-color: rgba(0, 0, 0, 0.8);
}
.unit.unit--boxLinked:hover:not(.unit--hasBackground):before,
.unit.unit--boxLinked:focus:not(.unit--hasBackground):before,
.unit.unit--boxLinked:hover:not(.unit--hasBackground):after,
.unit.unit--boxLinked:focus:not(.unit--hasBackground):after {
  transform: scale(1.6);
}
.unit.unit--imageLeftRight .unit__background {
  float: left;
  width: 100%;
}
.unit.unit--imageLeftRight .head {
  display: none;
}
.section--two .unit.unit--unitGameDay,
.section--five .unit.unit--unitGameDay {
  padding: 15px;
  background-color: var(--backgroundColor);
  color: var(--textColor);
}
.section--two .unit.unit--unitGameDay .unit__body,
.section--five .unit.unit--unitGameDay .unit__body {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.section--two .unit.unit--unitGameDay .part.part--gameDay,
.section--five .unit.unit--unitGameDay .part.part--gameDay,
.section--two .unit.unit--unitGameDay .part.part--link,
.section--five .unit.unit--unitGameDay .part.part--link {
  width: auto;
  min-width: 50px;
  font-weight: 700;
}
.section--two .unit.unit--unitGameDay .part.link,
.section--five .unit.unit--unitGameDay .part.link {
  display: flex;
  align-items: center;
  padding-left: 33px;
  background: url(/images/ticket-icon-white.svg) no-repeat left center;
  background-size: 23px 20px;
}
.section--two .unit.unit--unitGame,
.section--five .unit.unit--unitGame {
  padding: 15px;
  background-color: var(--backgroundColor);
  color: var(--textColor);
}
.section--two .unit.unit--unitGame .part.part--gameTime,
.section--five .unit.unit--unitGame .part.part--gameTime,
.section--two .unit.unit--unitGame .part.part--image,
.section--five .unit.unit--unitGame .part.part--image {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.section--two .unit.unit--unitGame .part.part--gameTime,
.section--five .unit.unit--unitGame .part.part--gameTime {
  position: relative;
  margin-top: 0;
  margin-bottom: calc(var(--spacePart) * 3);
  padding-left: 30px;
  background: url(/images/clock-icon.svg) no-repeat left center;
  background-size: 18px 18px;
}
.section--two .unit.unit--unitGame .part.part--gameTime:after,
.section--five .unit.unit--unitGame .part.part--gameTime:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: calc(var(--spacePart) * -1.5);
  width: 100%;
  height: 1px;
  background-color: #DCDCDC;
}
.section--two .unit.unit--unitGame .part.part--image,
.section--five .unit.unit--unitGame .part.part--image {
  position: relative;
  margin-bottom: 0;
  justify-content: flex-start;
  width: 50%;
}
.section--two .unit.unit--unitGame .part.part--image .cb-image-figure,
.section--five .unit.unit--unitGame .part.part--image .cb-image-figure {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.section--two .unit.unit--unitGame .part.part--image .cb-image-caption,
.section--five .unit.unit--unitGame .part.part--image .cb-image-caption {
  box-sizing: border-box;
  margin-top: 0;
  padding: 0 10px;
}
.section--two .unit.unit--unitGame .part.part--image .cb-image-container,
.section--five .unit.unit--unitGame .part.part--image .cb-image-container {
  flex-shrink: 0;
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid var(--textColor);
  overflow: hidden;
}
.section--two .unit.unit--unitGame .part.part--image .cb-image-container img,
.section--five .unit.unit--unitGame .part.part--image .cb-image-container img {
  width: 28px !important;
  height: 28px;
}
.section--two .unit.unit--unitGame .part.part--image:last-child,
.section--five .unit.unit--unitGame .part.part--image:last-child {
  justify-content: flex-end;
  text-align: right;
}
.section--two .unit.unit--unitGame .part.part--image:last-child .cb-image-figure,
.section--five .unit.unit--unitGame .part.part--image:last-child .cb-image-figure {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.section--two .unit.unit--unitGame .part.part--image:last-child:before,
.section--five .unit.unit--unitGame .part.part--image:last-child:before {
  content: ":";
  position: absolute;
  left: -10px;
  top: 0;
  width: 20px;
  height: 100%;
  font-weight: 700;
  text-align: center;
}
.unit--fold.unit.unit--imageLeftRight div.more,
.unit--fold.unit.unit--mapLeftRight div.more {
  box-sizing: border-box;
  display: block !important;
  overflow: hidden;
  max-height: 0;
}
.unit--fold.unit.unit--imageLeftRight div.more .part,
.unit--fold.unit.unit--mapLeftRight div.more .part {
  transform: translateY(-40px);
  opacity: 0;
}
.unit--foldOpen.unit--fold.unit.unit--imageLeftRight div.more,
.unit--foldOpen.unit--fold.unit.unit--mapLeftRight div.more {
  max-height: 500px;
  transition: all 0.8s;
}
.unit--foldOpen.unit--fold.unit.unit--imageLeftRight div.more .part,
.unit--foldOpen.unit--fold.unit.unit--mapLeftRight div.more .part {
  transition: all 0.6s, opacity 0.3s 0.2s;
  transform: translateY(0);
  opacity: 1;
}
.unit--fold.unit.unit--imageLeftRight .unit__foot .ctrl .link,
.unit--fold.unit.unit--mapLeftRight .unit__foot .ctrl .link {
  position: relative;
  box-sizing: border-box;
  padding-left: 40px;
  display: inline-block;
  margin: var(--spacePart) 10px;
  text-transform: uppercase;
}
.unit--fold.unit.unit--imageLeftRight .unit__foot .ctrl .link:before,
.unit--fold.unit.unit--mapLeftRight .unit__foot .ctrl .link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  background: url(/images/arrow-down-red.svg) no-repeat left bottom;
  background-size: 15px 25px;
  transition: all 0.3s;
}
.unit--foldOpen.unit--fold.unit.unit--imageLeftRight .unit__foot .ctrl .link:before,
.unit--foldOpen.unit--fold.unit.unit--mapLeftRight .unit__foot .ctrl .link:before {
  transform: rotate(180deg) translateY(50%);
}
.unit--fold.unit.unit--imageLeftRight .unit__foot .ctrl .link:hover,
.unit--fold.unit.unit--mapLeftRight .unit__foot .ctrl .link:hover,
.unit--fold.unit.unit--imageLeftRight .unit__foot .ctrl .link:focus,
.unit--fold.unit.unit--mapLeftRight .unit__foot .ctrl .link:focus {
  font-weight: 700;
}
.unit--fold.unit.unit--imageLeftRight .unit__foot .ctrl .link:hover:before,
.unit--fold.unit.unit--mapLeftRight .unit__foot .ctrl .link:hover:before,
.unit--fold.unit.unit--imageLeftRight .unit__foot .ctrl .link:focus:before,
.unit--fold.unit.unit--mapLeftRight .unit__foot .ctrl .link:focus:before {
  height: 25px;
}
.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) {
  width: calc(100% - 20px);
  margin: 0 10px;
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}
.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) + .unit--fold {
  border-top: none;
}
.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) .part {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) .less .part {
  margin-top: 0;
  margin-bottom: 0;
}
.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) .more {
  box-sizing: border-box;
  display: block !important;
  overflow: hidden;
  max-height: 0;
}
.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) .more .part {
  transform: translateY(-40px);
  opacity: 0;
}
.unit--foldOpen.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) .more {
  max-height: 500px;
  transition: all 0.8s;
}
.unit--foldOpen.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) .more .part {
  transition: all 0.6s, opacity 0.3s 0.2s;
  transform: translateY(0);
  opacity: 1;
}
.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) .fold-toggle {
  display: block;
  text-decoration: none;
  padding: var(--spacePart) 0;
  position: relative;
  transition: all 0.2s;
}
.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) .fold-toggle:before,
.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) .fold-toggle:after {
  content: '';
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -1px;
  z-index: 2;
  width: 21px;
  height: 5px;
  background-color: #E92B22;
  transition: all 0.2s;
}
.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) .fold-toggle.fold-toggle--closed:before {
  transform: translateY(-50%) rotate(-90deg);
}
.unit.unit--fold:not(.unit.unit--imageLeftRight, .unit.unit--mapLeftRight) .unit__foot {
  display: none;
}
.part {
  box-sizing: border-box;
}
.part.pict {
  display: flex;
  justify-content: center;
}
.part.pict .cb-image-figure {
  position: relative;
  width: auto;
}
.part.pict .cb-image-container {
  width: auto;
}
.unit.unit--unitSponsors .part.pict {
  background-color: #FFFFFF;
  padding: 10px;
  filter: grayscale(1);
  transition: all 0.3s;
}
.unit.unit--unitSponsors .part.pict:hover,
.unit.unit--unitSponsors .part.pict:focus {
  filter: grayscale(0);
}
.part.link a.open {
  position: relative;
  z-index: 1;
  display: inline-block;
  box-sizing: border-box;
  width: 110px;
  padding-left: 0;
  padding-right: 25px;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.3s;
}
.part.link a.open:before {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  top: 50%;
  width: 15px;
  height: 100%;
  transform: translateY(-50%);
  background: url(/images/arrow-ticket.svg) no-repeat right center;
  background-size: 120px 15px;
  transition: all 0.3s;
}
.part.link a.open:hover,
.part.link a.open:focus {
  font-weight: 700;
}
.part.link a.open:hover:before,
.part.link a.open:focus:before {
  width: 120px;
}
.part.load a.load {
  position: relative;
  display: block;
  z-index: 1;
  box-sizing: border-box;
  padding: 22px 55px 22px 20px;
  border: 1px solid var(--linkColor);
  background-color: transparent;
  background-image: none;
  text-transform: uppercase;
  color: var(--linkColor);
  text-decoration: none;
  transition: all 0.3s;
}
.part.load a.load:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: var(--linkColor);
  transition: all 0.3s;
}
.part.load a.load:after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 30px;
  background: url(/images/download_icon.svg) no-repeat right center;
  background-size: 100% 100%;
  transition: all 0.3s;
}
.part.load a.load:hover,
.part.load a.load:focus {
  color: var(--linkHoverColor);
}
.part.load a.load:hover:before,
.part.load a.load:focus:before {
  width: 100%;
}
.part.load a.load:hover:after,
.part.load a.load:focus:after {
  background-image: url(/images/download_icon-white.svg);
}
@media (max-width: 1279px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .section {
    transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  body.cb-toggle-target-active .section {
    transform: translateY(200px);
  }
  body.cb-toggle-target-active .section.section--header {
    transform: none;
  }
  .navi {
    display: none;
  }
}
.togglenavigation {
  position: fixed;
  top: 20px;
  right: var(--deskSpace);
  z-index: 2001;
  width: 35px;
  height: 20px;
  cursor: pointer;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 2px;
  background-color: #E92B22;
  border-radius: 2px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: #E92B22;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-navigation div.sub1 > .item {
  display: inline-block;
  margin: 25px 0;
}
.mobile-navigation div.sub1 > .item.init {
  margin-top: 85px;
}
.mobile-navigation div.sub1 > .item.exit {
  margin-bottom: 85px;
}
.mobile-navigation div.sub1 > .item > .menu {
  position: relative;
  display: inline-block;
  padding: 10px 0;
  color: #000000;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.mobile-navigation div.sub1 > .item > .menu:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  height: 3px;
  width: 78px;
  background-color: #E92B22;
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 35px;
  height: 24px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.cb-album {
  position: relative;
  padding-top: var(--spacePart);
  padding-bottom: var(--spacePart);
}
.cb-album .body-mobile {
  display: none !important;
}
.cb-album h2 {
  display: none;
}
.cb-album .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cb-album .part {
  width: 100% !important;
  margin: 0 !important;
}
.cb-album .part.desc {
  display: none;
}
#root.ie11 .cb-album img {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-album.cb-album .scan {
  margin-top: 40px !important;
}
.cb-album.cb-album .scan:first-child {
  margin-top: 0 !important;
  margin-bottom: 40px !important;
}
.cb-album .scan table,
.cb-album .scan tbody,
.cb-album .scan tr {
  float: left;
  display: block;
  width: 100%;
}
.cb-matrix .body {
  margin-top: -0.5%;
  margin-bottom: -0.5%;
}
.cb-matrix .part {
  margin-top: 0.5% !important;
  margin-bottom: 0.5% !important;
}
.cb-matrix .part.foto {
  height: auto !important;
  box-sizing: border-box;
}
.cb-matrix .part.foto a.foto,
.cb-matrix .part.foto span.foto {
  display: block;
  height: 0;
  padding-bottom: 60%;
  position: relative;
  overflow: hidden;
}
.cb-matrix .part.foto img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-matrix .part.foto a.foto.zoom img {
  transform: scale(1.1);
}
.cb-matrix.cb-matrix-columns-1 .part.foto {
  width: 100% !important;
}
.cb-matrix.cb-matrix-columns-2 .part.foto {
  width: 49.5% !important;
}
.cb-matrix.cb-matrix-columns-3 .part.foto {
  width: 32.66666667% !important;
}
.cb-matrix.cb-matrix-columns-4 .part.foto {
  width: 24.25% !important;
}
.cb-matrix.cb-matrix-columns-5 .part.foto {
  width: 19.2% !important;
}
.cb-matrix.cb-matrix-columns-6 .part.foto {
  width: 15.83333333% !important;
}
.cb-matrix.cb-matrix-columns-7 .part.foto {
  width: 13.42857143% !important;
}
.cb-matrix.cb-matrix-columns-8 .part.foto {
  width: 11.625% !important;
}
.cb-matrix .scan tr {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.cb-matrix .scan td {
  float: left;
  display: block;
  margin: 0 10px;
  font-size: 16px;
  line-height: 1.25;
}
.cb-matrix .scan td:first-child {
  margin-left: 0;
}
.cb-matrix .scan td:last-child {
  margin-right: 0;
}
.cb-matrix .scan td.prev,
.cb-matrix .scan td.next {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 20px;
  height: 20px;
}
.cb-matrix .scan td.prev a,
.cb-matrix .scan td.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.cb-matrix .scan td.prev:empty,
.cb-matrix .scan td.next:empty {
  opacity: 0.5;
  pointer-events: none;
}
.cb-matrix .scan td.next a {
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.cb-matrix .scan td a {
  color: #000000;
}
.cb-matrix .scan td .same {
  color: #E92B22;
}
.cb-strips .part.foto {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-strips-images {
  float: left;
  width: 100%;
  height: 0 !important;
  padding-bottom: 56.25%;
  position: relative;
}
.cb-strips-images a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.96);
  z-index: 2;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1), transform 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-images a.is-active {
  z-index: 3;
  pointer-events: auto;
  opacity: 1;
  transform: scale(1);
}
.cb-strips-images img {
  opacity: 1 !important;
  display: block !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-strips .scan tbody > tr {
  float: left;
  width: 100%;
  display: flex;
}
.cb-strips .scan tbody > tr > td {
  float: left;
  width: 100%;
  display: block;
}
.cb-strips .scan td.prev,
.cb-strips .scan td.next {
  margin-right: 1.25%;
  width: 6.25%;
}
.cb-strips .scan td.next {
  margin-right: 0;
  margin-left: 1.25%;
}
.cb-strips .scan div.prev,
.cb-strips .scan div.next {
  float: left;
  width: 100%;
  height: 100%;
}
.cb-strips .scan div.prev a,
.cb-strips .scan div.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
  transition: background 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips .scan div.prev a:hover,
.cb-strips .scan div.next a:hover {
  background-position: 40% 50%;
}
.cb-strips .scan div.next a {
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.cb-strips .scan div.next a:hover {
  background-position: 60% 50%;
}
.cb-strips .scan td.cb-strips-thumbs {
  width: 85%;
}
.cb-strips-container {
  float: left;
  width: 100%;
}
.cb-strips-container .body {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.cb-strips-container .part.foto {
  position: relative;
}
.cb-strips-container .part.foto a.foto,
.cb-strips-container .part.foto span.foto {
  float: left;
  width: 100%;
  display: block;
  height: 0;
  position: relative;
  padding-bottom: 60%;
  opacity: 0.4;
  transition: opacity 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-container .part.foto a.foto:hover,
.cb-strips-container .part.foto span.foto:hover,
.cb-strips-container .part.foto a.foto.is-active,
.cb-strips-container .part.foto span.foto.is-active {
  opacity: 1;
}
.cb-strips-container .part.foto a.foto .cb-zoom-indicator,
.cb-strips-container .part.foto span.foto .cb-zoom-indicator {
  display: none;
}
.cb-strips-container .part.foto img {
  opacity: 1 !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips-container .part.foto a.foto:hover img {
  transform: scale(1.2);
}
.cb-strips-4 .cb-strips-container .part.foto {
  width: 23.95833333% !important;
}
.cb-strips-6 .cb-strips-container .part.foto {
  width: 15.50925926% !important;
}
.cb-strips-8 .cb-strips-container .part.foto {
  width: 11.28472222% !important;
}
.cb-strips-10 .cb-strips-container .part.foto {
  width: 8.75% !important;
}
.cb-slideshow-images {
  float: left;
  width: 100%;
}
.cb-slideshow .part.foto,
.cb-slideshow .part.crossslideContainer {
  max-height: 1000000000px !important;
  max-width: 100% !important;
  height: 0 !important;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}
.cb-slideshow a.foto {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 1;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-slideshow a.foto.is-activated {
  z-index: 2;
  pointer-events: auto;
  opacity: 1;
}
.cb-slideshow img {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  border: none !important;
  z-index: 1;
}
.cb-slideshow img.is-activated {
  z-index: 2;
}
#view .cb-slideshow.cb-manual img {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-manual a.foto.is-activated img {
  transform: scale(1);
}
#view .cb-slideshow.cb-fading img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-fading img.is-activated,
#view .cb-slideshow.cb-fading .cb-image-figure.is-activated img {
  opacity: 1 !important;
  transform: scale(1);
}
#view .cb-slideshow.cb-sliding img {
  opacity: 0 !important;
  width: 110% !important;
  max-width: 110% !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowSlide;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-sliding img.is-activated,
#view .cb-slideshow.cb-sliding .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowSlide {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -10%;
  }
  100% {
    margin-left: 0;
  }
}
#view .cb-slideshow.cb-moving img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowMove;
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-moving img.is-activated,
#view .cb-slideshow.cb-moving .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowMove {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.cb-slideshow .head {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 4;
  height: 0;
  width: 100%;
}
.cb-slideshow .ctrl {
  float: left;
  width: 100%;
}
.cb-slideshow .ctrl a {
  display: block;
  position: absolute;
  top: 0;
  left: 50px;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-size: 100px 50px;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-left-red.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-slideshow .ctrl a:hover {
  width: 100px;
}
.cb-slideshow .ctrl a.fade {
  opacity: 0.4;
  cursor: default;
  width: 50px;
}
.cb-slideshow .ctrl .next a {
  left: unset;
  right: 50px;
  background-position: right center;
  background-image: url(/images/arrow-right-red.svg);
}
@media (max-width: 1279px) {
  .cb-slideshow .ctrl a {
    left: var(--deskSpace);
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.5);
  }
  .cb-slideshow .ctrl a:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    transform: translateX(-50%) translateY(-50%);
    background: url(/images/arrow-left-red-mobile.svg) no-repeat left center;
    background-size: 66px 40px;
    transition: all 0.3s;
  }
  .cb-slideshow .ctrl a:hover {
    width: 60px;
  }
  .cb-slideshow .ctrl .next a {
    right: var(--deskSpace);
    background: rgba(255, 255, 255, 0.5);
  }
  .cb-slideshow .ctrl .next a:before {
    background-position: right center;
    background-image: url(/images/arrow-right-red-mobile.svg);
  }
}
@media (max-width: 767px) {
  .cb-slideshow .ctrl a {
    width: 23px;
    height: 23px;
  }
  .cb-slideshow .ctrl a:before {
    width: 15px;
    height: 15px;
    transform: translateX(-50%) translateY(-50%);
    background-size: 25px 15px;
  }
  .cb-slideshow .ctrl a:hover {
    width: 23px;
  }
}
.crossslide {
  float: left;
  width: 100%;
  max-width: 100% !important;
  position: static !important;
  padding: 0 !important;
}
@media (max-width: 1279px) {
  .navbar {
    box-sizing: border-box;
    padding: 15px 0;
  }
}
.section--four .area {
  width: calc(100% + 20px);
  margin-left: -10px;
}
.section--four .area .unit .part {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}
.section--four .area .unit--unitSponsors .part {
  width: calc(100% / 2 - 20px);
}
.section--four .area .unit--imageLeftRight .unit__background {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.section--four .area .unit--imageLeftRight .unit__background,
.section--four .area .unit--imageLeftRight .part {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}
.section--map {
  background-color: #F0F0F0;
}
.map-wrapper {
  float: left;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 190px 190px 50px;
  grid-template-rows: 100px auto 1fr 100px;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (min-width: 1279px) {
  .map-wrapper {
    padding: 0 max(5vw, calc((100vw - 1800px) / 2));
    box-sizing: border-box;
  }
}
@media (max-width: 1279px) {
  .map-wrapper {
    grid-template-columns: 10vw minmax(0, 1fr) 10vw;
    grid-template-rows: 20px auto 20px;
  }
}
@media (max-width: 767px) {
  .map-wrapper {
    grid-template-columns: 20px minmax(0, 1fr) 20px;
    grid-template-rows: 20px auto 20px;
  }
}
.map-wrapper:has(.pin.is-active) {
  background-color: rgba(0, 0, 0, 0.3);
}
.map-wrapper:has(.pin.is-active) .map__background {
  filter: brightness(70%);
}
.map-wrapper:has(.pin.is-active) .pin:not(.is-active):not(:hover) {
  filter: brightness(50%);
}
.map-container {
  grid-area: 1 / 1 / 5 / 5;
}
@media (max-width: 1279px) {
  .map-container {
    grid-area: 1 / 1 / 4 / 4;
    overflow-x: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .map-container::-webkit-scrollbar {
    display: none;
  }
}
.map {
  float: left;
  width: 100%;
  position: relative;
}
@media (max-width: 1279px) {
  .map {
    width: 150%;
  }
}
@media (max-width: 767px) {
  .map {
    width: 150%;
  }
}
.map__background {
  width: 100%;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.map__darken {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  background-color: rgba(0, 0, 0, 0.3);
}
.map__pins {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}
.pin {
  position: absolute;
  left: var(--x);
  top: var(--y);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 1.04166667vw;
}
@media (max-width: 1600px) {
  .pin {
    left: calc(var(--x) - 1%);
    top: calc(var(--y) - 1.5%);
  }
}
@media (max-width: 767px) {
  .pin {
    left: calc(var(--x) - 1%);
    top: calc(var(--y) - 1.5%);
  }
}
.pin__icon {
  width: clamp(1.875rem, 1.307rem + 2.42vw, 3.125rem);
  aspect-ratio: 50 / 63;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-pin.svg);
}
.pin__label {
  background-color: #E92B22;
  color: #FFFFFF;
  font-size: clamp(0.875rem, 0.818rem + 0.24vw, 1rem);
  line-height: 1.11111111;
  padding: clamp(0.25rem, 0.193rem + 0.24vw, 0.375rem) clamp(0.375rem, 0.261rem + 0.48vw, 0.625rem);
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (max-width: 1279px) {
  .pin__label {
    display: none;
  }
}
.pin.is-active .pin__label {
  opacity: 1;
}
@media (hover: hover) and (pointer: fine) {
  .pin:hover .pin__label,
  .pin:focus .pin__label {
    opacity: 1;
  }
}
.popup__close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-close.svg);
}
.popups {
  grid-area: 2 / 2 / 3 / 4;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "popup";
  pointer-events: none;
  align-items: flex-start;
}
@media (max-width: 1279px) {
  .popups {
    grid-area: 2 / 2 / 3 / 3;
    position: absolute;
  }
}
.popup {
  grid-area: popup;
  background-color: #FFFFFF;
  opacity: 0;
  transition: all 0.4 s cubic-bezier(0.65, 0, 0.35, 1);
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  left: unset!important;
  top: unset!important;
}
.popup.is-active {
  z-index: 2;
  opacity: 1;
  pointer-events: all;
}
.popup__section {
  width: 100%;
  position: relative;
}
.popup__section--hero {
  aspect-ratio: 400 / 260;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-image: fill 0 linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
  /* your gradient here */
}
.popup__part--herotitle {
  position: relative;
  z-index: 1;
  font-size: 33px;
  line-height: 0.84848485;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
  color: #FFFFFF;
  z-index: 2;
}
.popup__part--herotitle:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 20px);
  height: 33px;
  background-color: #E92B22;
}
.popup__part--heroimage {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  flex-shrink: 0;
}
.popup__section--content {
  flex: 1 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  text-align: center;
}
.popup__part--icon {
  width: 60px;
}
.popup--5 .popup__part--icon {
  width: 85px;
}
.popup__part--line {
  border: 2px solid #E92B22;
  width: 90px;
  margin-top: -10px;
}
.popup__part--lead {
  font-size: 20px;
  line-height: 1.3;
  font-style: italic;
}
.popup__part--list li {
  font-size: 16px;
  line-height: 1.5;
  list-style-type: "+ ";
}
.popup__part--button {
  position: relative;
  z-index: 1;
  display: inline-block;
  box-sizing: border-box;
  padding-right: 0;
  padding-left: 25px;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.3s;
  margin-top: auto;
}
.popup__part--button:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 50%;
  width: 17px;
  height: 100%;
  transform: translateY(-50%);
  background: url(/images/arrow-ticket.svg) no-repeat right center;
  background-size: 120px 15px;
  transition: all 0.3s;
}
.popup__part--button:hover,
.popup__part--button:focus {
  font-weight: 700;
}
/*# sourceMappingURL=./screen-small.css.map */